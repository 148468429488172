import axios from "axios";
import moment from "moment";
import { Cookies } from "react-cookie-consent";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import { toast } from "react-toastify";

export const AppConfigData = {
  // APIBasePath: "https://live-crmapi.inntelligentcrm.com/", // prod  
  // ClientBasePath: "https://live-client.inntelligentcrm.com",//  Client prod 

  APIBasePath: "http://qa-crmapi.inntelligentcrm.com/", // qa  
  ClientBasePath: "http://qa-client.inntelligentcrm.com",//  Client qa
};

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve) => {
        let baseURL: any = ""
        loader.file.then((file) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            baseURL = reader.result;
            resolve({
              default: baseURL
            });
          };
        });
      });
    }
  };
}

export const CommonService = {
  unauthorizedAction: () => {
    Swal.fire({
      title: 'Unauthorized!',
      text: "It appears that you don't have permission to access this.",
      icon: 'error',
      confirmButtonText: 'Close'
    })
  },
  uploadPlugin: (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  },
  convertUTCToLocal: (date, format = "") => {
    let localDate = "";
    if (date !== null && date !== "" && date !== undefined) {
      // let stillUtc = Moment.utc(date).toDate();
      localDate = moment(date)
        .local()
        .format(format != "" ? format : "MM-DD-YYYY");
    }
    return localDate;
  },
};
//#region  Common API 
export const SourceAPIList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetProjectTermByCategory",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      Cookies.remove("SystemSettingTime");
      // caches.keys().then((names) => {
      //   names.forEach((name) => {
      //     caches.delete(name);
      //   });
      // });
      localStorage.clear()
      window.location.pathname = '/'
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
////#endregion

//#region  Toaster Notifications
export function ToasterSuccess(Value) {
  toast.success(Value, {
    autoClose: 1000,
    pauseOnFocusLoss: false,
    theme: "colored",
  });
}
export function ToasterError(Value) {
  toast.error(Value, {
    autoClose: 2000,
    pauseOnFocusLoss: false,
    theme: "colored",
  });
}
//#endregion

//#region  Handle Common Validations

export function fnCheckValidationOfObject(obj) {

  let IsValid = true;
  if (obj.errors !== null) {
    if (obj.errors.ValidationRules) {

      for (let i = 0; i < obj.errors.ValidationRules.length; i++) {
        obj.errors[obj.errors.ValidationRules[i].FieldName] = "";
      }

      for (let i = 0; i < obj.errors.ValidationRules.length; i++) {
        let objRules = obj.errors.ValidationRules[i];

        if (objRules !== null) {

          // Required validation :
          if (objRules.ValidationType.toLowerCase() === "required") {
            if (
              obj[objRules.FieldName] === "" ||
              obj[objRules.FieldName] === null ||
              obj[objRules.FieldName] === undefined
            ) {
              IsValid = false;
              obj.errors[objRules.FieldName] = objRules.ValidationMessage;
            }

            if (objRules.FieldName === "businessTypes_Term") {
              if (obj.businessTypes_Term === "Select Business Type") {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              }
            }

          }

          if (
            obj[objRules.FieldName] !== "" &&
            obj[objRules.FieldName] !== null &&
            obj[objRules.FieldName] !== undefined
          ) {

            //  Range validation
            if (objRules.ValidationType.toLowerCase() === "range") {
              //  Range validation
              if (objRules.FieldName === "contactNo") {
                if (obj.contactNo.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              // if (objRules.FieldName === "contactNo") {
              //   if (obj.contactNo.length < 10) {
              //     IsValid = false;
              //     obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              //   }
              // }
              if (objRules.FieldName === "invoiceContactPhone") {
                // if (obj.invoiceContactPhone.length < 10) {
                if (obj.invoiceContactPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "phone") {
                // if (obj.phone.length < 10) {
                if (obj.phone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "primaryContact") {
                // if (obj.primaryContact.length < 10) {
                if (obj.primaryContact.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }

              if (objRules.FieldName === "contractedProperties") {
                if (obj.contractedProperties.length > 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "contractedUsers") {
                if (obj.contractedUsers.length > 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "subscriptionAmount") {
                if (obj.subscriptionAmount.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "HTARGETBAR") {
                if (obj.HTARGETBAR.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "HTARGETLNR") {
                if (obj.HTARGETBAR.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "Rate") {
                if (obj.Rate.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "roleCode") {
                if (obj.roleCode.length > 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "PricePerMonth") {
                if (obj.PricePerMonth.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "PricePerYear") {
                if (obj.PricePerYear.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }


            }


            if (obj.errors[objRules.FieldName] === "" && objRules.ValidationType.toLowerCase() === "min" && objRules.Min !== null && objRules.Min !== undefined && objRules.Min !== "") {

              if (obj[objRules.FieldName] !== null && obj[objRules.FieldName] !== undefined && obj[objRules.FieldName] !== "") {
                if (parseFloat(obj[objRules.FieldName]) < objRules.Min) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage.replace("{0}", objRules.Min);
                }
              }
            }

            if (obj.errors[objRules.FieldName] === "" && objRules.ValidationType.toLowerCase() === "max" && objRules.Max !== null && objRules.Max !== undefined && objRules.Max !== "") {
              if (obj[objRules.FieldName] !== null && obj[objRules.FieldName] !== undefined && obj[objRules.FieldName] !== "") {
                if (parseFloat(obj[objRules.FieldName]) > objRules.Max) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage.replace("{0}", objRules.Max);
                }
              }
            }

            if (obj.errors[objRules.FieldName] === "" && objRules.ValidationType.toLowerCase() === "minmax" && objRules.Max !== null && objRules.Max !== undefined && objRules.Max !== "" && objRules.Min !== null && objRules.Min !== undefined && objRules.Min !== "") {

              if (obj[objRules.FieldName] !== null && obj[objRules.FieldName] !== undefined && obj[objRules.FieldName] !== "") {
                if (parseFloat(obj[objRules.FieldName]) < objRules.Min || parseFloat(obj[objRules.FieldName]) > objRules.Max) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage.replace("{0}", objRules.Min).replace("{1}", objRules.Max);
                }
              }
            }

            // Masking Validation
            if (objRules.ValidationType.toLowerCase() === "mobile-mask") {
              // if (!obj[objRules.FieldName].toString().match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)) {
              // if (!obj[objRules.FieldName].toString().match(/^[0-9]{10}$/)) {
              //   IsValid = false;
              //   obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              // }

              if (objRules.FieldName === "APhoneno") {
                if (obj.APhoneno.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "OOWPhone") {
                if (obj.OOWPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "OGMPhone") {
                if (obj.OGMPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "ODSPhone") {
                if (obj.ODSPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "OCMPhone") {
                if (obj.OCMPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "OSMPhone") {
                if (obj.OSMPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "ORMPhone") {
                if (obj.ORMPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "OREPhone") {
                if (obj.OREPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "ORSPhone") {
                if (obj.ORSPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }

            }

            // Email Validation
            if (
              obj.errors[objRules.FieldName] === "" &&
              objRules.ValidationType.toLowerCase() === "email"
            ) {
              var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
              if (!obj[objRules.FieldName].toString().match(mailformat)) {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              }
            }

            //  Number validation
            if (
              objRules.ValidationType.toLowerCase() === "number" && obj.errors[objRules.FieldName] === ""
            ) {
              var mailformat = /^[0-9]/;
              if (!obj[objRules.FieldName].toString().match(mailformat)) {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              }
            }

            //  Compare Field Value
            if (
              obj.errors[objRules.FieldName] === "" && obj.errors[objRules.CompareFieldName] === "" &&
              objRules.ValidationType.toLowerCase() === "comparefieldvalue"
            ) {
              var mailformat = /^[0-9]*$/;
              if (obj[objRules.FieldName] !== obj[objRules.CompareFieldName]) {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              }
            }

            //date validation
            if (objRules.ValidationType.toLowerCase() === "date") {
              if (moment(obj[objRules.FieldName]) <= moment('1900-01-01')) {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              }
            }
            if (objRules.ValidationType.toLowerCase() === "checkbox") {
              if (obj[objRules.FieldName].length === 0) {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              }
            }
          }
        }
      }
    }
  }
  return { isValid: IsValid, obj: obj };
}

//Masking custome try
// export function fnCheckMaskingOnField(e, masking) {
//   ;
//   let placeholder = e.target.placeholder;
//   let Values = e.target.value;
//   let NewVal = placeholder;
//   if (Values !== "") {
//     let TotalNoOfValues = 0;
//     for (var i = 0; i < placeholder.length; i++) {
//       if (placeholder.charAt(i) === "_") {
//         TotalNoOfValues++;
//       }
//       Values = Values.replace(placeholder.charAt(i), "");
//     }
//     for (var i = 0; i < Values.length; i++) {
//       if (i < TotalNoOfValues)
//         NewVal = NewVal.replace("_", Values.charAt(i));
//     }

//     e.target.value = NewVal;
//     e.target.setSelectionRange(NewVal.indexOf("_") + 1, NewVal.indexOf("_") + 1);
//   }

//   // for (var i = 0; i < PHArray.length; i++) {
//   //   if (PHArray[i] === "") {
//   //     PHArray[i] = "_";
//   //   }
//   // }

//   // let placeholder = e.target.placeholder

//   // let NewVal = placeholder;
//   // let Values = e.target.value;


//   // if (e.target.value !== "") {
//   //   for (var i = 0; i < placeholder.length; i++) {
//   //     Values = Values.replace(placeholder.charAt(i), "");
//   //   }

//   //   for (var i = 0; i < Values.length; i++) {
//   //     NewVal = NewVal.replace("_", Values.charAt(i));
//   //   }
//   //   e.target.value = NewVal;
//   // }
//   // if (e.target.value.toString().match(masking)) {
//   //   return true;
//   // }
//   // else {
//   //   //e.preventDefault();
//   //   return false;
//   // }

// }

//#endregion

//#region Display Text
export const DisplayText = {
  // Client
  Code: "Code",
  FirstName: "First Name",
  LastName: "Last Name",
  CompanyName: "Company Name",
  UserName: "User Name",
  PhoneNo: "Phone No.",
  Email: "Email",
  ContractedUsers: "Contracted Users",
  ContractedProperties: "Contracted Hotels",
  Subscription: "Subscription",
  InvoiceNo: "Invoice No.",
  Amount: "Amount",
  InvoiceContactName: "Invoice Contact Name",

  // Portfolio
  PortfolioName: "Portfolio Name",

  // Properties
  Chain: "Chain",
  TotalRooms: "Total Rooms",
  SecondaryName: "Secondary Name",
  PropertyCode: "Property Code",
  HotelCode: "Hotel Code",
  WebsiteLink: "Website Link",
  Password: "Password",
  DUNSNumber: "DUNS Number",
  FEMANumber: "FEMA Number",
  TARGETBARWeekdayRate: "TARGET BAR Weekday Rate",
  TARGET_LNR_AND_RFP_RATE: "TARGET LNR & RFP RATE",
  // Tab 2 
  Name: "Name",

  // Subscription
  Discount: "Discount",
  AmountPaid: "Amount Paid",

  // User 
  CountryCode: "Country Code",
  Ext: "Ext",

  // User Role
  RoleCode: "Role Code",
  RoleName: "Role Name",
  Description: "Description",

  // Additional Settings
  TermCode: "Term Code",
  Term: "Term",
  DefaultValue: "Default Value",

  // Change Password
  CurrentPassword: "Current Password",
  NewPassword: "New Password",
  ConfirmPassword: "Confirm Password",
};

//#endregion

//#region SystemSettingTime

export function getDateInFormat(date) {
  if (date !== null && date !== "" && date !== undefined)
    return moment(date).format("MM/DD/YYYY"
    );
  else return "";
}

export function getTimeInFormat(date) {
  let xu = "HH:mm:ss";
  if (date !== null && date !== "" && date !== undefined) {
    let timeBev = CommonService.convertUTCToLocal(date, xu);
    if (timeBev !== "" && timeBev) {
      let timeBevAr = timeBev.split(":");
      let remCountBev: any = timeBevAr[0];
      let time_type_bev = "AM";
      if (parseInt(timeBevAr[0]) > 12) {
        remCountBev = parseInt(timeBevAr[0]) - 12;
        time_type_bev = "PM";
      } else if (parseInt(timeBevAr[0]) == 12 || parseInt(timeBevAr[0]) == 12) {
        remCountBev = 12;
        if (parseInt(timeBevAr[0]) == 12) {
          time_type_bev = "PM";
        } else {
          time_type_bev = "AM";
        }
      } else {
        //This is applied by heena, for if {parseInt(timeBevAr[0])} == 0.
        if (parseInt(timeBevAr[0]) == 0 || parseInt(timeBevAr[0]) == 0) {
          remCountBev = 12;
        }
      }
      if (
        timeBevAr[1] &&
        timeBevAr[1] != undefined &&
        (timeBevAr[1].includes("AM") ||
          timeBevAr[1].includes("am") ||
          timeBevAr[1].includes("PM") ||
          timeBevAr[1].includes("pm"))
      ) {
        timeBev = remCountBev.toString().padStart(2, "0") + ":" + timeBevAr[1];
      } else {
        timeBev =
          remCountBev.toString().padStart(2, "0") + ":" + timeBevAr[1] + " " + time_type_bev;
      }
    }
    return timeBev;
  } else {
    return "";
  }
}
export function GetPageRight(module_name) {

  const dataSelector: any = useSelector((state: any) => state.session);
  let right_json = {
    is_view: false,
    is_create: false,
    is_delete: false,
    is_update: false,
    is_export: false,
  }

  let resData = dataSelector.user_rights !== null && dataSelector.user_rights.filter((itm) => itm.moduleName == module_name)

  if (resData.length) {
    right_json = {
      is_view: resData[0].isCreate,
      is_create: resData[0].isDelete,
      is_delete: resData[0].isExport,
      is_update: resData[0].isUpdate,
      is_export: resData[0].isView,
    }
  }
  return right_json
}